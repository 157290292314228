import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { FaCalendar } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";

const Meta = props => {
  const { prefix, tags: tags, theme } = props;

  return (
    <div className="meta">
      {tags && tags.length > 0 ? (
        <div className="tags">
          {tags.map(tag => {
            return (
              <a key={tag} href={`/tag/${tag}`}>
                {tag}
              </a>
            );
          })}
        </div>
      ) : null}
      {tags && tags.length > 0 ? (
        <div className="primary-tag">
          <a href={`/tag/${tags[0]}`}>{tags[0]}</a>
        </div>
      ) : null}
      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .meta {
          display: flex;
          flex-flow: row wrap;
          font-size: 0.8em;
          margin: ${theme.space.m} 0;
          background: transparent;

          :global(svg) {
            fill: ${theme.icon.color};
            margin: ${theme.space.inline.xs};
          }
          span {
            align-items: center;
            display: flex;
            text-transform: uppercase;
            margin: ${theme.space.xs} ${theme.space.s} ${theme.space.xs} 0;
          }

          a {
            display: inline-block;
            padding: 12px 25px;
            border-radius: 3px;
            background: ${theme.colour.grey.superlight};
            color: ${theme.colour.grey.meddark};
            margin-right: 10px;
            margin-bottom: 10px;
            font-family: ${theme.font.reidweb.subheader};
            font-weight: 400;
            text-align: center;
            font-size: ${theme.font.size.xxxs};
            line-height: normal;
            text-transform: uppercase;
            outline: none !important;
          }

          .tags {
            float: left;
            width: 65%;

            a {
              :hover {
                background: #dee0e5;
                color: #4f5357;
              }

              :first-child {
                display: none;
              }
            }
          }

          .primary-tag {
            width: 35%;

            a {
              padding: 12px 25px;
              float: right;
              width: 90%;
              background: ${theme.colour.blue.medium};
              color: #fff;
              margin-right: 0;

              :hover {
                background: ${theme.colour.blue.dark};
              }
            }
          }

          :before {
            content: "";
            display: block;
            height: 1px;
            width: 70%;
            margin: 0 auto 60px;
            background: #dfdfdf;
          }
        }
        @from-width tablet {
          .meta {
            margin: ${`calc(${theme.space.m} * 1.5) 0 ${theme.space.m}`};
          }
        }

        @media (max-width: 630px) {
          .meta {
            text-align: center;

            .tags {
              width: 100%;
              float: none;
              text-align: center;

              a {
                float: none;
                display: inline-block;
              }
            }

            .primary-tag {
              clear: both;
              float: none;
              width: 100%;
              margin-top: 30px;
              a {
                width: 90%;
                margin: 0 5%;
              }
            }
          }
        }
      `}</style>
    </div>
  );
};

Meta.propTypes = {
  prefix: PropTypes.string.isRequired,
  tags: PropTypes.array,
  theme: PropTypes.object.isRequired
};

export default Meta;
