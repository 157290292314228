import React from "react";
import PropTypes from "prop-types";
import "prismjs/themes/prism-okaidia.css";

import asyncComponent from "../AsyncComponent";
import Bodytext from "../Article/Bodytext";
import Meta from "./Meta";

const Share = asyncComponent(() =>
  import("./Share")
    .then(module => {
      return module.default;
    })
    .catch(error => {})
);

const Post = props => {
  const {
    post,
    post: {
      html,
      fields: { prefix, slug },
      frontmatter: { title, author, tags }
    },
    facebook,
    theme
  } = props;

  return (
    <React.Fragment>
      <Bodytext html={html} theme={theme} />
      <footer>
        <Meta prefix={prefix} tags={tags} theme={theme} />
        <Share post={post} theme={theme} />
      </footer>
    </React.Fragment>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  facebook: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Post;
